.button {
  display: flex;
  align-items: center;
}
.button button {
  font-size: var(--large-size);
  border: none;
  padding: 10px 0px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s;
}
.button button:hover {
  transition: all 0.2s;
  transform: scale(0.95);
}/*# sourceMappingURL=button_style.css.map */