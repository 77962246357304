.goalsCounter {
    padding-top: 100px;
    margin-left: 20px;

    // xx buts affichés
    & h2 {
        color: var(--detail-color);
        font-weight: 900;
        font-size: var(--xxl-large-size);
    }

    // Soit xx% des buts de Cristiano Ronaldo en carrière
    & p {
        color: var(--secondary-color);
        font-size: var(--large-size);
    }

    &__hide {
        color: var(--detail-color);
        position: fixed;
        bottom: 25px;
        right: 30px;
        z-index: 100;
        box-shadow: inset;
    }
}