.propertyDetails {
  width: 95%;
  margin: auto;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--main-color);
  border-radius: 10px;
}
.propertyDetails__prop-value {
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding: 15px 0px;
  padding-left: 15px;
  color: var(--secondary-color);
  font-size: var(--x-large-size);
}
.propertyDetails__prop-value span:nth-child(1) {
  font-weight: lighter;
  font-size: var(--medium-size);
}
.propertyDetails__prop-value span:nth-child(2) {
  font-weight: 800;
  color: var(--detail-color);
  font-size: var(--x-large-size);
}
.propertyDetails__img {
  padding: 5px 0px;
  padding-right: 15px;
  min-width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.propertyDetails__img img {
  max-width: 50px;
  height: 50px;
  -o-object-fit: contain;
     object-fit: contain;
}/*# sourceMappingURL=propertyDetails_style.css.map */