.goalsCounter {
  padding-top: 100px;
  margin-left: 20px;
}
.goalsCounter h2 {
  color: var(--detail-color);
  font-weight: 900;
  font-size: var(--xxl-large-size);
}
.goalsCounter p {
  color: var(--secondary-color);
  font-size: var(--large-size);
}
.goalsCounter__hide {
  color: var(--detail-color);
  position: fixed;
  bottom: 25px;
  right: 30px;
  z-index: 100;
  box-shadow: inset;
}/*# sourceMappingURL=goalsCounter.css.map */