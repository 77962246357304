@mixin displayFlex($flexDirection: row, $justifyContent: space-between, $alignItems: center) {
    display: flex;
    flex-direction: $flexDirection;
    justify-content: $justifyContent;
    align-items: $alignItems;
  }
  
  body {
    background-color: var(--background-color);
  }

  // <article className="card">
.card {
    background-color: var(--main-color);
    padding: 15px 0px;
    margin: 15px 20px;
    width: 350px;
    color: var(--secondary-color);
    border-radius: 10px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    border: 1px solid transparent;
    cursor: pointer;
    z-index: 0;

    &:hover {
        border: 1px solid var(--detail-color);
    }

    &__link {
        text-decoration: none;
        color: inherit;
    }

    &__header {
        @include displayFlex();
        padding: 0px 10px;
    }

    &__goal-number {
        @include displayFlex($flexDirection: column);
        transition: all 0.2s;

        // Text "goal" or "But n°" in french
        & span:nth-child(1){
            font-size: var(--medium-size);
        }

        // The number of the goal
        & span:nth-child(2){
            font-size: var(--xxl-large-size);
            font-weight: 900;
            color: var(--detail-color);
        }
    }

    &__opponent {
        font-weight: 900;
        position: relative;
        text-align: center;

        &::before {
            content: "vs";
            position: absolute;
            font-size: var(--x-small-size);
            font-weight: 300;
            top: -15px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__club {
        & img {
            height: 40px;
        }
    }

    // <section>
    &__date-competition, .card__finishing-assist {
        @include displayFlex($justifyContent: space-between);
        font-size: var(--medium-size);
        padding: 10px 10px;

        // <span> date and competition
        span {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--background-color);
            border-radius: 10px;
            padding: 3px 5px;
            color: var(--secondary-color);
            width: 45%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        
            // The icon
            & svg {
                margin-right: 5px;
                color: var(--detail-color);
            }
        }
        
    }

    &__stats {
        display: flex;
        justify-content: space-evenly;
        font-size: var(--medium-size);
        width: 100%;

        &.first {
            padding: 10px 0px;
        }

        &__date, .card__stats__competition, .card__stats__finishing , .card__stats__assist  {
            width: 45%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            border-radius: 10px;
            padding: 4px 5px;
            background-color: var(--background-color);

            & span {
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    &__change-direction {
        display:inline-block;
        color: var(--secondary-color);
        margin: 15px 20px;
        border-bottom: 1px solid white;
        cursor: pointer;
    }
}

.card {

    @media only screen and (min-width: 800px) and (max-width: 1170px) {
        width: 45%;
    }

    @media only screen and (max-width: 840px) {
        width: 98%;
    }
}