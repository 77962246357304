.cardDetails {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 500px;
  height: 100%;
  background-color: var(--background-color);
  z-index: 150;
  overflow-y: scroll;
  overflow-x: hidden;
  border-right: 1px solid var(--detail-color);
  transform: translateX(-100%);
  transition: all 5.3s;
}
.cardDetails.active {
  transform: translateX(0%);
  transition: all 5.3s;
}
.cardDetails header {
  width: 100%;
  margin: 15px 0px;
}
.cardDetails header div {
  width: 95%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--secondary-color);
  font-weight: 800;
  font-size: var(--xxl-large-size);
}
.cardDetails header div span:nth-child(2) svg {
  cursor: pointer;
}
.cardDetails__video {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 15;
}
.cardDetails__video video {
  width: 95%;
  margin: auto;
  overflow: hidden;
  border-radius: 10px;
}
.cardDetails__video a {
  text-decoration: none;
  color: var(--secondary-color);
  margin-top: 15px;
}
.cardDetails__title {
  width: 95%;
  margin: auto;
  color: var(--secondary-color);
  font-size: calc(var(--xxl-large-size) - 5px);
  margin-top: 30px;
  margin-bottom: 20px;
  z-index: 15;
}/*# sourceMappingURL=cardDetails.css.map */