.header {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  padding: 10px 0px;
  color: var(--secondary-color);
  background-color: var(--background-color);
  font-size: var(--x-large-size);
  font-weight: 900;
}
.header__link {
  font-size: var(--medium-size);
  color: var(--detail-color);
}
.header__link a {
  text-decoration: none;
  outline: none;
  color: var(--detail-color);
}/*# sourceMappingURL=header_style.css.map */