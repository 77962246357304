.addnewgoal {
  color: white;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 695px;
}
.addnewgoal__property-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50px;
  margin: 15px;
  padding: 15px;
  background-color: var(--main-color);
  border-radius: 15px;
}
.addnewgoal__property-input label {
  padding-bottom: 7px;
}
.addnewgoal__property-input input {
  border: none;
  border-radius: 10px;
  padding: 3px 10px;
}
.addnewgoal__container-submit {
  max-width: 695px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.addnewgoal__code {
  color: yellow;
  display: flex;
  flex-direction: column;
  width: 200px;
  margin-left: 50px;
  margin-top: 500px;
  margin-bottom: 200px;
}
.addnewgoal__code input {
  border: none;
  border-radius: 10px;
  padding-left: 5px;
}/*# sourceMappingURL=addnewgoal_style.css.map */