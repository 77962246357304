body {
  background-color: var(--background-color);
}

.card {
  background-color: var(--main-color);
  padding: 15px 0px;
  margin: 15px 20px;
  width: 350px;
  color: var(--secondary-color);
  border-radius: 10px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  border: 1px solid transparent;
  cursor: pointer;
  z-index: 0;
}
.card:hover {
  border: 1px solid var(--detail-color);
}
.card__link {
  text-decoration: none;
  color: inherit;
}
.card__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
}
.card__goal-number {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s;
}
.card__goal-number span:nth-child(1) {
  font-size: var(--medium-size);
}
.card__goal-number span:nth-child(2) {
  font-size: var(--xxl-large-size);
  font-weight: 900;
  color: var(--detail-color);
}
.card__opponent {
  font-weight: 900;
  position: relative;
  text-align: center;
}
.card__opponent::before {
  content: "vs";
  position: absolute;
  font-size: var(--x-small-size);
  font-weight: 300;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
}
.card__club img {
  height: 40px;
}
.card__date-competition, .card .card__finishing-assist {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: var(--medium-size);
  padding: 10px 10px;
}
.card__date-competition span, .card .card__finishing-assist span {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-color);
  border-radius: 10px;
  padding: 3px 5px;
  color: var(--secondary-color);
  width: 45%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card__date-competition span svg, .card .card__finishing-assist span svg {
  margin-right: 5px;
  color: var(--detail-color);
}
.card__stats {
  display: flex;
  justify-content: space-evenly;
  font-size: var(--medium-size);
  width: 100%;
}
.card__stats.first {
  padding: 10px 0px;
}
.card__stats__date, .card__stats .card__stats__competition, .card__stats .card__stats__finishing, .card__stats .card__stats__assist {
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 10px;
  padding: 4px 5px;
  background-color: var(--background-color);
}
.card__stats__date span, .card__stats .card__stats__competition span, .card__stats .card__stats__finishing span, .card__stats .card__stats__assist span {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card__change-direction {
  display: inline-block;
  color: var(--secondary-color);
  margin: 15px 20px;
  border-bottom: 1px solid white;
  cursor: pointer;
}

@media only screen and (min-width: 800px) and (max-width: 1170px) {
  .card {
    width: 45%;
  }
}
@media only screen and (max-width: 840px) {
  .card {
    width: 98%;
  }
}/*# sourceMappingURL=card_style.css.map */