:root {
    --x-small-size: 8px;
    --small-size: 10px;
    --medium-size: 12px;
    --x-medium-size: 14px;
    --large-size: 16px;
    --x-large-size: 18px;
    --xxl-large-size: 26px;

    --background-color: #0c0f1d;
    --main-color: #1b1d2a;
    --main-hover-color: #1f2230;
    --secondary-color: rgb(255, 255, 255);
    --detail-color: #3dff87;

    /* --background-color: #f3f3f3;
    --main-color: #fcfbfb;
    --main-hover-color: #ececec;
    --secondary-color: rgb(60, 60, 60);
    --detail-color: #4784ff; */

}

* {
    padding: 0;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
}

.notification {
    background: var(--detail-color);
}

.global-loading {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--detail-color);
}