.searchGoal {

    // The filter for searching a goal
    &__filter {
        position: fixed;
        top: 0; left: 0;
        max-width: 550px;
        height: 100vh;
        background-color: var(--background-color);
        border-right: 1px solid var(--detail-color);
        z-index: 150;
        display: block;
        overflow: scroll;
        transform: translateX(-100%);
        transition: transform 0.3s ease-in-out;
        padding-bottom: 25px;

        &.active {
            transform: translateX(0);
        }

        &-background {
            position: fixed;
            top: 0; left: 0;
            width: 100%;
            height: 100vh;
            z-index: 100;
            backdrop-filter: blur(10px);
            transform: translateX(-100%);
            transition: transform 0.3s ease-in-out;
            &.active {
                transform: translateX(0);
            }
        }

        // "Rechercher un but" container
        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: var(--detail-color);
            padding: 15px 25px;
            font-weight: 900;
            
            & h2 {
                font-size: var(--x-large-size);
            }

            & svg {
                cursor: pointer;
            }
        }

        // Container properties (ex: team, opponent)
        &__property {
            margin-top: 15px;
            color: var(--secondary-color);
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
        }

        // ex: <span>Équipe</span>
        &__span {
            padding: 10px 20px;
            font-weight: 400;
            border-bottom: 4px solid var(--main-color);
            cursor: pointer;

        }

        &__span-selected {
            padding: 10px 20px;
            border-bottom: 4px solid var(--detail-color);
            cursor: pointer;
        }

        // <h2> PROPERTY </h2>
        &__selected-property {
            color: var(--secondary-color);
            margin: 35px 0px 15px 20px;
        }

        // Container of the input search
        &__input-search {
            width: 100%;
            margin: 0px 0px 0px 20px;

            & input {
                width: 80%;
                border-radius: 20px;
                border: none;
                padding: 0px 25px;
                height: 35px;
            }
        }

        // Select all / Unselect all
        &__select-deselect {
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin: 35px 0px 35px 20px;
            color: var(--secondary-color);

            & span {
                display: flex;
                align-items: center;
                padding: 5px 0px;
                cursor: pointer;
                gap: 15px;
                transition: transform .5s;
                
                &:hover {
                    transform: translateX(15px);
                }
            }
        }

        // Container of the checkboxes
        &__list {
            width: 95%;
            margin: 35px auto;
            height: 250px;
            overflow-y: scroll;
            border-radius: 25px;

        &::-webkit-scrollbar {
            width: 10px;
            background-color: var(--main-color);
            border-radius: 20px;
            
        }
        &::-webkit-scrollbar-thumb {
            background: var(--detail-color);
            border-radius: 20px;
        }
        }

        // Div label + input checkboxes for each value
        &__checkbox {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            color: var(--detail-color);
            width: 95%;
            margin: auto;

            &:hover {
                color: var(--detail-color);
            }

            & label {
                width: 100%;
                padding: 10px 0px;
                cursor: pointer;
            }

            & input {
                padding: 10px 0px;
            }
        }
    }
}