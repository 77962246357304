.searchGoal__filter {
  position: fixed;
  top: 0;
  left: 0;
  max-width: 550px;
  height: 100vh;
  background-color: var(--background-color);
  border-right: 1px solid var(--detail-color);
  z-index: 150;
  display: block;
  overflow: scroll;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  padding-bottom: 25px;
}
.searchGoal__filter.active {
  transform: translateX(0);
}
.searchGoal__filter-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
}
.searchGoal__filter-background.active {
  transform: translateX(0);
}
.searchGoal__filter__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--detail-color);
  padding: 15px 25px;
  font-weight: 900;
}
.searchGoal__filter__header h2 {
  font-size: var(--x-large-size);
}
.searchGoal__filter__header svg {
  cursor: pointer;
}
.searchGoal__filter__property {
  margin-top: 15px;
  color: var(--secondary-color);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.searchGoal__filter__span {
  padding: 10px 20px;
  font-weight: 400;
  border-bottom: 4px solid var(--main-color);
  cursor: pointer;
}
.searchGoal__filter__span-selected {
  padding: 10px 20px;
  border-bottom: 4px solid var(--detail-color);
  cursor: pointer;
}
.searchGoal__filter__selected-property {
  color: var(--secondary-color);
  margin: 35px 0px 15px 20px;
}
.searchGoal__filter__input-search {
  width: 100%;
  margin: 0px 0px 0px 20px;
}
.searchGoal__filter__input-search input {
  width: 80%;
  border-radius: 20px;
  border: none;
  padding: 0px 25px;
  height: 35px;
}
.searchGoal__filter__select-deselect {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 35px 0px 35px 20px;
  color: var(--secondary-color);
}
.searchGoal__filter__select-deselect span {
  display: flex;
  align-items: center;
  padding: 5px 0px;
  cursor: pointer;
  gap: 15px;
  transition: transform 0.5s;
}
.searchGoal__filter__select-deselect span:hover {
  transform: translateX(15px);
}
.searchGoal__filter__list {
  width: 95%;
  margin: 35px auto;
  height: 250px;
  overflow-y: scroll;
  border-radius: 25px;
}
.searchGoal__filter__list::-webkit-scrollbar {
  width: 10px;
  background-color: var(--main-color);
  border-radius: 20px;
}
.searchGoal__filter__list::-webkit-scrollbar-thumb {
  background: var(--detail-color);
  border-radius: 20px;
}
.searchGoal__filter__checkbox {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  color: var(--detail-color);
  width: 95%;
  margin: auto;
}
.searchGoal__filter__checkbox:hover {
  color: var(--detail-color);
}
.searchGoal__filter__checkbox label {
  width: 100%;
  padding: 10px 0px;
  cursor: pointer;
}
.searchGoal__filter__checkbox input {
  padding: 10px 0px;
}/*# sourceMappingURL=searchGoal_style.css.map */